import React, { PureComponent } from 'react';
import Layout from '../components/layout';
import SimpleSlider from '../components/slider';
import SEO from '../components/seo';

class HomePage extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            width: 0,
        };
    }

    componentDidMount() {
        if (typeof window !== `undefined`) {
            this.setState({
                width: window.innerWidth,
            });
        }
    }

    render() {
        const { data } = this.props;
        const { width } = this.state;
        const doc = data.prismicHomePage.data;
        const bottomRightText = doc.bottom_right_text.text;

        let slides = [];

        if (doc.body[0].items.length > 0) {
            slides = doc.body[0].items.map(slide => {
                const image = slide.image_slider.url;
                let thumbnail = slide.image_slider.thumbnails;

                if (!thumbnail.Mobile) {
                    return;
                }

                return width < 768 && thumbnail ? thumbnail.Mobile.url : image;
            });
        }

        return (
            <Layout>
                <SEO title="Home" />
                <main className="main-home">
                    <SimpleSlider slides={ slides } />
                    <p className="webaddress">{ bottomRightText }</p>
                </main>
            </Layout>
        );
    }
}

export const query = graphql`
    query HomePageTemplateSlug {
        prismicHomePage {
            data {
                bottom_right_text {
                    text
                }
                body {
                    ... on PrismicHomePageBodyBackgroundImages {
                        items {
                            image_slider {
                                url
                                thumbnails {
                                    Mobile {
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default HomePage;
