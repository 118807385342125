import React from 'react';
import Slider from 'react-slick';
import Img from 'gatsby-image';

const SimpleSlider = ({ slides }) => {
    const settings = {
        arrows: false,
        autoplay: true,
        fade: true,
        infinite: true,
        pauseOnHover: false,
        speed: 1200,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <Slider {...settings}>
            { slides.map(image => (
                <div>
                    <div
                        className="slider-img"
                        style={{ backgroundImage: `url(${image})` }}
                    ></div>
                </div>
            )) }
        </Slider>
    );
};

export default SimpleSlider;
